<template>
  <v-container>
    <v-card>
      <v-col>
        {{ setting }} 번째 질문 입력하기
        <v-form>
          <v-text-field
            v-model="q"
            :counter="200"
            label="질문 내용"
            @blur="setQ"
          />
          <v-select
            v-if="isMBTI"
            v-model="typeOf"
            :items="abcd"
            label="이 질문으로 알수 있는 MBTI 유형"
            @blur="setTypeOf"
          />

          <v-select
            v-if="isScore || allSelectType2 == 0"
            v-model="choiceNumber"
            :items="choiceCount"
            label="문항수"
            @blur="setChoiceNumber"
          />
          <!-- <v-select
            v-if="isScore"
            v-model="typeOf"
            :items="choiceCount"
            label="이 질문의 항목수"
            @blur="setTypeOf"
          /> -->

          <v-container
            v-if="isScore && allSelectType2 == 0 && allSelectType == 0"
          >
            <v-row
              v-for="index in choiceNumber"
              :key="index"
            >
              <v-col>
                <v-card>
                  <v-container>
                    <choices
                      :setting="setting"
                      :is-choice="false"
                      :index="index"
                      :score-list="scoreList"
                    />
                  </v-container>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
          <!-- <div v-if="isScore">
            <v-text-field
              v-if="isScore"
              v-model="q"
              :counter="200"
              label="답 입력"
              @blur="setQ"
            />
          </div> -->

          <v-select
            v-if="isTopScore"
            v-model="typeOf"
            :items="abcd"
            label="질문 유형"
            @blur="setTypeOf"
          />
          <div v-if="isOX">
            <v-row>
              <v-col> 이 질문의 정답은 </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-btn
                  v-model="OorX"
                  :color="setOColor"
                  block
                  @click="setCorrect('O')"
                >
                  O
                </v-btn>
              </v-col>
              <v-col>
                <v-btn
                  v-model="OorX"
                  :color="setXColor"
                  block
                  @click="setCorrect('X')"
                >
                  X
                </v-btn>
              </v-col>
            </v-row>
          </div>
          <v-select
            v-if="allSelectType == 'no' || !isScore"
            v-model="selectSelectKey"
            :items="selectKey"
            item-text="name"
            item-value="key"
            label="답변 타입"
            @blur="setSelectKey"
          />
        </v-form>
      </v-col>
    </v-card>
  </v-container>
</template>

<script>
export default {
  components: {
    Choices: () => import('@/components/create/Choice'),
  },
  props: {
    setting: Number,
    allSelectType: String,
    resultType: String,
  },
  data: () => ({
    q: '',
    abcd: [],
    selectSelectKey: 10,
    selectKey: [],
    typeOf: '',
    isMBTI: false,
    isScore: false,
    isTopScore: false,
    isOX: false,
    setOColor: 'gray',
    setXColor: 'gray',
    OorX: '',
    scoreList: [],
    choiceCount: [],
    choiceNumber: 2,
    allSelectType2: 0,
  }),
  mounted() {
    //   { key: 0, name:'16종류: 16가지 성격 유형'},
    //   { key: 1, name:'5종류: 점수'},
    //   { key: 2, name:'5종류: 점수 누적으로 가장 높은 것'},
    //   { key: 3, name:'점수: OX 퀴즈'}
    this.abcd = this.$store.state.data.abcd
    this.scoreList = this.$store.state.data.scoreList
    this.choiceCount = this.$store.state.data.choiceCount
    this.setChoiceNumber()
    this.selectKey = this.$store.state.data.selectKey
    if (this.resultType === 'MBTI') {
      this.isMBTI = true
    } else if (this.resultType === 'SCORE') {
      this.isScore = true
      this.allSelectType2 = 0
    } else if (this.resultType === 'TYPE') {
      this.isTopScore = true
    } else if (this.resultType === 'OX') {
      this.isOX = true
    }
  },
  methods: {
    setQ() {
      const qNumber = 'q' + this.setting
      localStorage.setItem(qNumber, this.q)
    },
    setChoiceNumber() {
      const choiceCount = 'choiceCount' + this.setting
      localStorage.setItem(choiceCount, this.choiceNumber)
    },
    setTypeOf() {
      const typeOfNumber = 'type' + this.setting
      localStorage.setItem(typeOfNumber, this.typeOf)
    },
    setSelectKey() {
      const selectKeyNumber = 'selectKey' + this.setting
      localStorage.setItem(selectKeyNumber, this.selectSelectKey)
    },
    setCorrect(val) {
      this.OorX = val
      if (this.OorX === 'O') {
        this.setOColor = 'primary'
        this.setXColor = 'light-gray'
      } else if (this.OorX === 'X') {
        this.setOColor = 'light-gray'
        this.setXColor = 'red'
      }
      const OorXNumber = 'OorX' + this.setting
      localStorage.setItem(OorXNumber, this.OorX)

      this.typeOf = 'OX'
      this.setTypeOf()
      this.setSelectKey()
    },
  },
}
</script>

<style></style>
